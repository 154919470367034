.search-container {
  padding-bottom: 30px;
  position: absolute;
  width: 100%;
  bottom: 0;

  .search-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    perspective: 1000px;

    form {
      width: 100%;
    }

    .search-input {
      width: 100%;
      padding: 0 36px;
      padding-right: 60px;
      height: 36px;
      background-color: #f5f5f5;
      border-radius: 8px;
      -webkit-appearance: none;
      transition: all 0.4s var(--smooth-ease-1);

      &::-webkit-calendar-picker-indicator,
      &::-webkit-list-button {
        display: none !important;
        opacity: 0 !important;
        pointer-events: none !important;
        user-select: none !important;
      }

      &::placeholder {
        color: #aaa;
      }

      &:focus-visible {
        background-color: #fff;
        box-shadow: 0 0 0 4px #fff, 0 0 0 5px #ddd;
      }

      @media (max-width: 1000px) {
        height: 32px;
      }
    }

    .search-icon,
    .search-clear-btn {
      position: absolute;

      svg {
        fill: #999;
      }
    }

    .search-icon {
      perspective: 1000px;
      left: 12px;
      --size: 20px;
      height: var(--size);
      aspect-ratio: 1;
      transition: all 1s cubic-bezier(0, -0.01, 0, 1);
      filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0.8));
      user-select: none;
      pointer-events: none;

      svg {
        height: var(--size);
        width: var(--size);
        transition: all 0.8s var(--smooth-ease-1);
        transform-origin: center center;
        -webkit-backface-visibility: visible;
        backface-visibility: visible;
      }
    }

    &:focus-within {
      .search-icon {
        filter: drop-shadow(2px 16px 4px rgba(0, 0, 0, 0.3));
        transform: translateX(-20%) translateY(-2%) scale(1.2);

        svg {
          transition: transform 1.2s var(--smooth-ease-1),
            fill 0.8s var(--smooth-ease-1);
          transform: rotate3d(1, 1, 0, 180deg);
          fill: #000;
        }
      }
    }

    .search-clear-btn {
      right: 0;
      display: flex;
      align-items: center;
      background-color: rgba(255, 255, 255, 0);
      border: none;
      padding: 0 8px;
      transition: all 1s var(--smooth-ease-1);

      &:active {
        transform: scale(0.8);
      }
    }
  }
}
