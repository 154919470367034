.dialog-backdrop {
  position: fixed;
  inset: 0;
  background-color: rgba(240, 240, 240, 0.9);
  z-index: 10;
}
.dialog-content {
  max-width: fit-content;
  display: flex;
  flex-direction: column;
  position: fixed;
  inset: 0;
  z-index: 11;
  max-height: 90%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  top: 5%;
  outline: none;

  @media (max-width: 1000px) {
    inset: auto;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    max-height: 95%;
    max-width: 100%;
  }
}

.dialog-content-wrapper {
  border-radius: 20px;
  background-color: #fff;
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: calc(var(--max-page-width) - 4rem);
  border: none;
  padding: 30px 30px 25vh 30px;
  outline: none;
  position: relative;

  @media (max-width: 1000px) {
    width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.dialog-handle {
  z-index: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30px;
  position: absolute;
  display: none;
  top: 0;

  @media (max-width: 1000px) {
    display: flex;

    &::after {
      content: "";
      width: 50px;
      height: 4px;
      background-color: rgba(0, 0, 0, 0.25);
      border-radius: 500px;
    }
  }
}

.dialog-close-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 30px;
  right: 30px;

  @media (max-width: 1000px) {
    display: none;
  }
}
