.report-trend {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.25px;

  &::before {
    font-weight: 900;
    margin-right: 0.25em;
  }

  &.none {
    color: #555;
  }

  &.upper {
    &::before {
      color: var(--pink);
      content: "▲";
    }
  }

  &.lower {
    &::before {
      color: var(--dark-green);
      content: "▼";
    }
  }

  .trend-amount {
    display: none;
  }

  .trend-description {
    display: none;
  }

  &.report-trend-expanded {
    padding: 8px 12px;
    border-radius: 500px;
    font-size: 12px;

    &.upper {
      background-color: #faeef0;
    }

    &.lower {
      background-color: #e4f2eb;
    }

    &.same {
      background-color: #eee;
    }

    .trend-sign {
      display: none;
    }

    .trend-description {
      display: initial;

      &::before {
        content: " ";
      }
    }

    .trend-amount {
      border-left: 1px solid rgba(0, 0, 0, 0.25);
      padding-left: 1em;
      margin-left: 1em;
      display: inline;
    }
  }
}
