:root {
  --max-page-width: 800px;
}

html {
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

main {
  max-width: var(--max-page-width);
  margin: 0 auto;
  position: relative;
}

.art {
  margin-top: 8rem;
}

.loading {
  position: fixed;
  inset: 0;
  z-index: 15;
  background: linear-gradient(45deg, #fff 0%, #e1e2fb 30%, #fff 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 2rem;
  font-family: serif;
  font-style: italic;
  letter-spacing: -0.05ch;
  font-size: 1.2rem;
  pointer-events: none;
  gap: 20px;
  background-size: 1000% 100%;
  background-position: 100% 100%;
  animation: loading-bg-anim 3s cubic-bezier(0.4, 0.17, 0, 1) forwards;

  @keyframes loading-bg-anim {
    to {
      background-position: 0% 100%;
    }
  }

  .loading-date,
  .loading-week-number {
    font-size: 0.6em;
    font-style: normal;
    letter-spacing: 0.5ch;
    line-height: 1.5;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
  }

  .loading-week-number {
    position: absolute;
    bottom: 100px;
  }
}

header {
  position: relative;
  padding: 0 30px;

  .header-wrapper {
    padding: 100px 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    --h1-size: 40px;

    &.app-header {
      padding-bottom: 160px;
    }

    h1 {
      font-weight: 200;
      letter-spacing: -1px;
      font-size: var(--h1-size);
      font-family: var(--roboto-font-family);
      text-transform: capitalize;
      display: flex;
      flex-direction: column;
      gap: 8px;
      position: relative;

      .total-amt {
        position: absolute;
        bottom: calc(-50% - 8px);
        line-height: 1.25;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0;
        font-family: var(--inter-font-family);
      }

      &.pay {
        .total-amt {
          color: var(--purple);
        }
      }

      &.receive {
        .total-amt {
          color: var(--dark-green);
        }
      }

      .message {
        font-size: 22px;
        text-transform: initial;
        font-family: serif;
        font-style: italic;
        color: #555;
        margin-top: 4px;
        letter-spacing: -0.25px;
      }
    }

    .header-buttons {
      display: flex;
      gap: 10px;

      .header-button {
        background-color: #fff;
        height: 40px;
        width: 40px;
        border-radius: 500px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        cursor: pointer;
        will-change: transform;
        transition: all 1s var(--smooth-ease-1);

        &:active {
          transform: scale(0.9);
        }
      }
    }

    .reports-btn {
      border: 1px solid #ddd;
      user-select: none;
      background-color: #fff;
      z-index: 1;
      will-change: width;

      &:active {
        background-color: #ddd;
      }

      img {
        height: 22px;
        width: 22px;
      }
    }

    .profile-btn {
      overflow: hidden;
      border: none;

      img {
        height: 100%;
        width: 100%;
        pointer-events: none;
        border-radius: 500px;
        user-select: none;
      }
    }
  }
}

.unread {
  position: relative;

  &::before {
    position: absolute;
    content: "";
    display: flex;
    height: 10px;
    width: 10px;
    box-shadow: 0 0 0 4px #fff;
    background-color: var(--pink);
    color: #fff;
    text-transform: uppercase;
    border-radius: 500px;
    top: -2px;
    right: -2px;
    transform: scale(0);
    animation: unread-dot-intro 0.2s ease-out 0.4s forwards;

    @keyframes unread-dot-intro {
      to {
        transform: scale(1);
      }
    }
  }
}

.features {
  margin: 0;
  padding: 0;
  padding: 30px;
  padding-top: 0;
  list-style-type: none;

  li {
    margin-top: 0;
    display: flex;
    align-items: center;
    line-height: 1.5;
    font-size: 16px;

    @media (max-width: 1000px) {
      margin-top: 1rem;
    }

    span {
      margin-right: 20px;
      font-size: 2em;
    }
  }
}

.signin-btn {
  background-color: #000;
  color: #fff;
  border: none;
  margin-top: 2rem;
  margin-left: 1rem;
  border-radius: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 4rem;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  bottom: calc(env(safe-area-inset-bottom) + 30px);
  will-change: transform;
  transition: all 1s var(--smooth-ease-1);

  @media (max-width: 1000px) {
    padding: 2rem 6rem;
  }

  @media (max-width: 800px) {
    padding: 2rem;
    width: calc(100% - 2rem);
  }

  &:active {
    transform: scale(0.95);
  }
}

.new-entry-group-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 12;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;

  @media (max-width: 1000px) {
    align-items: flex-end;
    padding-bottom: env(safe-area-inset-bottom);
  }
}

.new-entry-group {
  position: relative;
  width: 500px;
  will-change: transform, opacity;
  background-color: #fff;
  box-shadow: rgba(50, 50, 93, 0.05) 0px -50px 100px -20px,
    rgba(0, 0, 0, 0.1) 0px -30px 60px -30px;
  border-radius: 20px;
  z-index: 12;

  @media (max-width: 1000px) {
    width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    &::after {
      content: "";
      background-color: #fff;
      height: 100vh;
      width: 100%;
      left: 0;
      position: absolute;
      top: 100%;
    }
  }
}

.dialog-backdrop {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(240, 240, 240, 0.9);
}

.add-entry-form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.add-entry-group {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px 20px;
  border-bottom: 1px solid #eee;

  label {
    font-size: 1rem;
    line-height: 1;
  }

  &:last-of-type {
    border-bottom: none;

    @media (max-width: 1000px) {
      padding-bottom: 40px;
    }
  }

  &.date-group {
    position: relative;
    gap: 0;

    input[type="date"]::-webkit-inner-spin-button,
    input[type="date"]::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
    }
  }
}

input {
  font-size: 1rem;
  line-height: 1;
  width: 100%;
  border: none;
  outline: none;
  padding: 0;
  background-color: #fff;
  -webkit-tap-highlight-color: transparent;

  &:focus-visible {
    outline: none;
    background-color: #fff;
  }

  &[type="date"] {
    color: inherit;
    font-size: 1rem;
    padding: 0;
  }
}

.add-record-btn {
  margin: 5px 0;
  color: #fff;
  background-color: #000;
  border-radius: 500px;
  padding: 20px;
  width: 100%;
  font-size: 1rem;
  font-weight: 500;
  border: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform;
  transition: transform 1s var(--smooth-ease-1);

  &:active {
    transform: scale(0.95);
  }
}

.type-actions {
  display: flex;
  align-items: center;
  font-size: 1rem;
  user-select: none;
  margin-top: 20px;
  padding: 0 20px;
  padding-bottom: 0;

  .actions {
    overflow: hidden;
    display: flex;
    margin-left: 4px;

    .button {
      font-size: 1rem;
      margin: 0;
      background-color: #fff;
      color: #aaa;
      padding: 8px 6px;
      cursor: pointer;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      will-change: transform;
      border-bottom: 2px solid #fff;
      transition: all 1s var(--smooth-ease-1);

      &.active {
        color: #000;
        background-color: #eee;
      }

      &:active {
        transform: scale(0.85);
        background-color: #aaa;
      }
    }
  }
}

.grouped-records {
  padding-bottom: 60vh;
  margin-inline: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .record-group-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 4px;

    .record-group-date {
      text-transform: uppercase;
      text-align: left;
      font-size: 10px;
      white-space: nowrap;
      display: flex;
      align-items: center;
      padding-bottom: 2px;
      letter-spacing: 1px;
    }

    .record-group-total {
      font-size: 12px;
      white-space: nowrap;
      display: flex;
      align-items: center;
      text-align: right;
    }
  }

  .records {
    width: 100%;

    &:not(:first-child) {
      margin-top: 50px;
    }

    .record-item {
      width: 100%;
      border: none;
      background: #fff;
      user-select: none;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      border-top: 1px solid #eee;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 12px 0;
      gap: 40px;
      cursor: pointer;
      will-change: transform;
      transition: all 1s var(--smooth-ease-1);

      &:active {
        transform: scale(0.975);
        border-radius: 10px;
        background-color: #ddd;
        padding: 12px;
      }

      &:first-of-type {
        border-top-color: #ddd;
      }

      .record-item-name-wrapper {
        display: flex;
        align-items: center;
        margin-top: 4px;
        gap: 4px;
      }

      .record-item-name {
        font-size: 12px;
      }

      .record-item-desc {
        font-weight: 500;
        font-size: 15px;
        line-height: 1.25;
      }

      .left-side {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        gap: 4px;
      }

      .right-side {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 6px;
        justify-content: space-between;
      }

      .record-item-amt {
        font-weight: 500;
        font-size: 15px;
      }

      .record-item-method {
        font-size: 10px;
        text-transform: uppercase;
        margin-top: 4px;
        color: #555;
        letter-spacing: 0.4px;
        padding-left: 20px;
        text-align: right;
        white-space: nowrap;
      }

      .settle-btn {
        margin-top: 6px;
        border: none;
        padding: 12px 18px;
        font-size: 12px;
        font-weight: 600;
        border-radius: 500px;
        user-select: none;
        background-color: #f5f5f5;
        color: #000;
        will-change: transform;
        transition: transform 1s var(--smooth-ease-1);

        &:active {
          transform: scale(0.9);
        }
      }
    }
  }

  .see-all-records-btn {
    border: 1px solid #ddd;
    background-color: #fff;
    padding: 20px 40px;
    border-radius: 500px;
    cursor: pointer;
    margin-top: 40px;
    will-change: transform;
    color: #000;
    font-size: 14px;
    transition: all 1s var(--smooth-ease-1);

    &:active {
      transform: scale(0.9);
    }
  }
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  padding: 2rem;
  padding-top: 15vh;
  height: 80vh;
  will-change: transform, opacity;
  user-select: none;

  p {
    white-space: pre;
    font-family: serif;
    text-align: center;
  }

  .toy {
    font-size: 2.5em;
  }

  .message {
    font-size: 1em;
    padding: 0.25em 0;
    font-style: italic;
    position: relative;
    overflow: hidden;

    &::after {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      right: 0;
      background: linear-gradient(
        to right,
        #fff 50%,
        #fff,
        rgba(255, 255, 255, 0)
      );
      animation: scrim-intro 2s cubic-bezier(0.35, 0.7, 0, 1) forwards;
      will-change: transform, opacity;
    }

    @keyframes scrim-intro {
      to {
        transform: translateX(-100%);
      }
    }
  }
}

.open-new-entry-container {
  margin: 0 auto;
  position: fixed;
  bottom: calc(env(safe-area-inset-bottom) + 110px);
  width: 100%;
  display: flex;
  justify-content: center;
  pointer-events: none;

  .open-new-entry-wrapper {
    width: 100%;
    max-width: var(--max-page-width);
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    pointer-events: none;
  }
}

.open-new-entry {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  user-select: none;
  color: #fff;
  border-radius: 500px;
  width: 80px;
  height: 80px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  will-change: transform;
  transition: transform 1s var(--smooth-ease-1);
  border: none;
  padding: 0;
  background-color: rgba(255, 255, 255, 0);
  pointer-events: auto;

  img {
    width: 100%;
    height: 100%;
    pointer-events: none;
    user-select: none;
  }

  .pressed {
    display: none;
  }

  &:active {
    // transform: scale(0.85);
    height: 76px;

    .coin {
      display: none;
    }

    .pressed {
      display: flex;
    }
  }
}

.close-new-entry {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  padding-bottom: 10px;

  h2 {
    font-size: 22px;
    font-weight: 400;
    font-family: var(--roboto-font-family);
  }

  .edit-entry-added-on {
    margin-top: 12px;
    font-size: 12px;
    color: #888;
  }

  .actions {
    display: flex;
    align-items: center;

    .action-button {
      height: 45px;
      width: 45px;
      color: #000;
      background-color: #fff;
      border-radius: 500px;
      border: none;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      will-change: transform;
      transition: transform 1s var(--smooth-ease-1);

      svg {
        height: 24px;
        width: 24px;
      }

      &:active {
        background-color: #aaa;
        transform: scale(0.75);
      }
    }

    .delete-entry-button {
      svg {
        fill: #d70015;
      }
    }
  }

  &.add-record-header {
    .add-record-header-handle {
      display: none;
    }
    @media (max-width: 1000px) {
      padding: 0;
      padding-top: 8px;

      .add-record-header-handle {
        display: flex;
      }
      h2,
      .actions {
        display: none;
      }
    }
  }
}

.about {
  .about-wrapper {
    padding: 3rem 0;
    padding-top: 1rem;
    padding-bottom: 2rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .avatar {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 80px;
        width: 80px;
        border-radius: 500px;
        margin-bottom: 10px;

        &.shadow {
          position: absolute;
          transform: scale(0.9) translateY(10px);
          filter: blur(20px) saturate(2);
          z-index: -1;
          opacity: 0.5;
        }
      }
    }

    h2 {
      font-family: var(--roboto-font-family);
      font-weight: 200;
      letter-spacing: -0.5px;
      line-height: 1.5;
      font-size: 28px;
      margin-bottom: 10px;
    }

    p {
      line-height: 1.5;
      font-family: serif;
      font-style: italic;
      font-size: 16px;
      margin-bottom: 1rem;

      a {
        color: inherit;
      }
    }

    .invite-btn {
      border: none;
      display: flex;
      align-items: center;
      background-color: #eee;
      -webkit-tap-highlight-color: transparent;
      gap: 10px;
      padding: 16px 20px;
      font-size: 14px;
      font-weight: 500;
      border-radius: 500px;
      margin-bottom: 1rem;
      color: #000;
      will-change: transform;
      transition: all 1s var(--smooth-ease-1);

      &:active {
        transform: scale(0.9);
        background-color: #ddd;
      }
    }

    .settings-group {
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;
      margin-top: 3rem;
      width: 100%;

      .settings-line {
        position: relative;
        border: none;
        margin: 0;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 2rem;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
        transition: transform 1s var(--smooth-ease-1);
        color: #000;
        text-decoration: none;
        border-top: 1px solid #eee;

        select {
          background-color: #fff;
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          user-select: none;
          color: rgba(255, 255, 255, 0);
          font-size: 0;
          position: absolute;
          inset: 0;
          max-width: 100%;
          text-align: right;
          padding-right: 2rem;
          border: none;
          cursor: pointer;

          &:hover {
            background-color: #f9f9f9;
          }
        }

        &:last-child {
          border-bottom: 1px solid #eee;
        }

        &:focus-visible,
        &:focus-within {
          z-index: 1;
        }

        &:hover {
          background-color: #f9f9f9;
        }

        .settings-line-label-group {
          display: flex;
          align-items: center;
          gap: 1rem;
          position: relative;
          z-index: 1;

          svg {
            height: 20px;
            width: 20px;
          }
        }

        .settings-line-label {
          font-size: 14px;
          user-select: none;
          font-weight: 400;
        }

        .settings-line-switch-group {
          display: flex;
          align-items: center;
          gap: 10px;
          user-select: none;
        }

        .settings-line-switch-label,
        .settings-line-value {
          font-size: 14px;
          color: #aaa;
          z-index: 1;
        }

        .settings-line-value {
          pointer-events: none;
        }
      }
    }
  }
}

.reports {
  overscroll-behavior: contain;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  z-index: 10;
  padding: 0 30px;
  overflow: scroll;
  padding-bottom: 300px;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 0 100vh rgba(240, 240, 240, 0.9);

  .reports-header {
    padding: 100px 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: calc(var(--max-page-width) - 4rem);

    .titles {
      .title {
        line-height: 1;
        font-weight: 200;
        letter-spacing: -1px;
        font-size: 36px;
        font-family: var(--roboto-font-family);
        text-transform: capitalize;
      }
    }

    .actions {
      button {
        background-color: #fff;
        height: 40px;
        width: 40px;
        border-radius: 500px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        cursor: pointer;
        will-change: width;
        transition: background-color 1s var(--smooth-ease-1);
        border: 1px solid #ddd;

        img {
          height: 22px;
          width: 22px;
        }

        &:active {
          background-color: #ddd;
          transform: scale(0.9);
        }
      }
    }
  }

  .reports-body {
    width: 100%;
    max-width: calc(var(--max-page-width) - 4rem);
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;

    .reports-list-item {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 10px;
      padding-top: 10px;
      padding-bottom: 40px;
      cursor: pointer;
      border: none;
      border-top: 1px solid #ddd;
      background-color: #fff;
      -webkit-tap-highlight-color: transparent;
      outline: none;
      color: #000;
      user-select: none;
      transition: all 1s var(--smooth-ease-1);

      &:active {
        background-color: #ddd;
        padding-left: 12px;
        padding-right: 12px;
        border-radius: 10px;
        transform: scale(0.975);
      }

      .reports-action-btn {
        border: 1px solid #ddd;
      }
    }
  }
}

.reports-summary-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  .month-name,
  .month-total-amt {
    line-height: 1;
  }

  .month-name {
    color: #000;
    font-size: 12px;
  }

  .month-total-amt {
    letter-spacing: -0.25px;
    font-size: 28px;
    display: flex;
    align-items: baseline;
    gap: 12px;
    font-weight: 300;
    font-feature-settings: var(--font-features-1);
  }
}

.month-report-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 40px;

  .reports-summary-group {
    @media (max-width: 1000px) {
      padding-top: 10px;
    }
    .month-name {
      font-size: 26px;
      color: #aaa;
    }

    .month-total-amt {
      flex-direction: column;
    }
  }
}

.month-report-body {
  .month-top-records-chips {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    list-style-type: none;

    @media (max-width: 1200px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 1000px) {
      grid-template-columns: repeat(2, 1fr);
    }

    li {
      border-radius: 10px;
      background-color: #f4f5fc;
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 6px;

      p {
        font-size: 1.2em;
      }

      .chip-label {
        font-size: 12px;
        margin-top: 4px;
        color: #555;
        text-align: left;
        white-space: nowrap;
      }
    }
  }

  .month-top-records {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    list-style-type: none;
    counter-reset: top-record-counter;

    .serif {
      font-family: "Georgia", serif;
      font-style: italic;
      font-size: 1.2em;
    }

    .month-top-records-title {
      font-size: 10px;
      text-transform: uppercase;
      letter-spacing: 1px;
      padding: 8px 0;
      border-bottom: 1px solid #eee;
    }

    li {
      line-height: 1.35;
      border-bottom: 1px solid #eee;
      counter-increment: top-record-counter;
      display: grid;
      grid-template-columns: auto 1fr auto;
      align-items: center;
      gap: 1em;
      padding: 8px 0;
      position: relative;
      font-size: 0.9em;

      &:last-child {
        border-bottom: none;
      }

      &:before {
        content: counter(top-record-counter) ".";
        font-size: 0.75em;
        font-weight: 600;
        color: #555;
      }

      .month-top-record,
      p {
        display: inline-flex;
        align-items: center;
        line-height: 1;
        gap: 8px;
      }
    }

    &:nth-of-type(1) {
      margin-top: 20px;
    }
  }
}

.icon-btn {
  background-color: transparent;
  border-radius: 500px;
  --size: 40px;
  height: var(--size);
  width: var(--size);
  display: flex;
  align-items: center;
  justify-content: center;
  will-change: transform;
  border: none;
  transition: all 1s var(--smooth-ease-1);
  outline: none;

  &:active {
    background-color: #ddd;
    transform: scale(0.9);
  }

  svg {
    --icon-size: 24px;
    height: var(--icon-size);
    width: var(--icon-size);
    transition: all 1s var(--smooth-ease-1);
  }

  &:focus-visible {
    border: 1px solid #ddd;
  }

  &:focus-visible,
  &:hover {
    svg {
      opacity: 1;
    }
  }
}
