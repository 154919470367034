.tabbar-wrapper {
    position: fixed;
    bottom: calc(env(safe-area-inset-bottom));
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}
.records-tabbar {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    max-width: 800px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 90px;
    z-index: 10;
    user-select: none;
    padding-bottom: 20px;
    background-color: rgba(250, 250, 250, 0.75);
    backdrop-filter: blur(20px) saturate(3);

    .tab {
        user-select: none;
        --webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 4px;
        width: 100%;
        height: 100%;
        font-size: 12px;
        font-weight: 500;
        padding-bottom: 10px;
        background-color: rgba(0, 0, 0, 0);
        border: none;
        color: #999;

        &:active {
            img {
                transform: scale(0.75);
            }
        }

        img {
            width: 24px;
            height: 24px;
            opacity: 0.25;
            pointer-events: none;
            will-change: transform;
            transition: transform 1s cubic-bezier(0, -0.01, 0, 1.31);
        }

        &.active {
            color: #000;

            img {
                opacity: 1;
            }
        }
    }
}
