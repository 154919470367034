@tailwind base;
@tailwind components;
@tailwind utilities;

/* variables */
:root {
  --inter-font-family: "Inter Variable", sans-serif;
  --font-features-1: "case" 0, "dlig" 0, "frac" 0, "dnom" 0, "numr" 0, "subs" 0,
    "sups" 0, "tnum", "zero" 0, "ss01" 0, "ss02" 0, "ss03" 0, "ss04" 0, "ss05" 0,
    "ss06" 0, "ss07" 0, "ss08" 0, "cv01", "cv02", "cv03", "cv04", "cv05", "cv06",
    "cv07" 0, "cv08", "cv09" 0, "cv10", "cv11", "cv12" 0, "cv13" 0, "cpsp",
    "c2sc" 0, "salt" 0, "aalt" 0, "calt", "ccmp", "locl", "kern";
  --roboto-font-family: "Roboto Slab", "Inter", sans-serif;
  --smooth-ease-1: cubic-bezier(0, -0.01, 0, 1.31);

  --gray-1: #eeeeee;
  --gray-2: #dddddd;
  --gray-3: #cccccc;
  --gray-4: #aaaaaa;
  --purple: #5731ff;
  --orange: #ff9500;
  --yellow: #ffd60a;
  --green: #30d158;
  --dark-green: #25b46c;
  --blue: #0a84ff;
  --pink: #ff375f;
  --brown: #ac8e68;

  --primary: var(--purple);
}

/* defaults and resets */
html {
  font-family: var(--inter-font-family);
  font-feature-settings: var(--font-features-1);
  scroll-padding: 100px;
  box-sizing: border-box;
}
body {
  overflow-y: scroll;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
body,
ul,
ol,
li,
h1,
h2,
h3,
h4,
p,
button {
  margin: 0;
  padding: 0;
  line-height: 1;
  font-family: var(--inter-font-family);
}
a[href]:empty::before {
  content: attr(href);
}
input[type="text"],
input[type="number"],
select,
textarea {
  font-size: 16px;
}
img {
  max-width: 100%;
  width: 100%;
}
button {
  cursor: pointer;
}
