.switch-track {
    display: flex;
    align-items: center;
    width: 38px;
    height: 24px;
    border-radius: 500px;
    cursor: pointer;
    background-color: #ddd;
    border: 2px solid #ddd;
    transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);

    &.checked {
        background-color: #000;
        border-color: #000;

        .switch-handle {
            transform: translateX(14px);
        }
    }

    &:active:not(.checked) {
        background-color: #aaa;
        border-color: #aaa;

        .switch-handle {
            transform: scale(0.85);
        }
    }

    &:active {
        .switch-handle {
            transform: scale(0.85) translateX(16px);
        }
    }

    .switch-handle {
        height: 20px;
        aspect-ratio: 1;
        background-color: #fff;
        border-radius: 500px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
        transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
}
