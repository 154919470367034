.snackbar-wrapper {
    position: fixed;
    top: calc(env(safe-area-inset-top));
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    left: 0;
    transform: translateY(-150%);
    will-change: transform;
    pointer-events: none;
    user-select: none;
    z-index: 13;

    .snackbar {
        display: flex;
        align-items: center;
        border-radius: 500px;
        background-color: #fff;
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
        padding: 20px 60px;
        position: relative;

        .snackbar-icon {
            position: absolute;
            left: 10px;
            z-index: 1;

            img {
                height: 28px;
                width: 28px;
                opacity: 0.85;
            }
        }

        .snackbar-message {
            font-size: 12px;
            font-weight: 600;
            overflow: hidden;
        }
    }
}
